function WhatWeOffer() {
    return (
        <section className="content-homepage homepage-about bg-white-homepage pt-150">
            <div className="container">
                <div className="row">
                    <h1 className="text-center">Co nabízíme?</h1>

                    <div className="col-sm-12 col-md-offset-4 col-md-8 ">
                        <div className="homepage-about-quote white">
                            <p>
                                Osobní přístup ke každému obchodnímu partnerovi na základě jeho požadavků a potřeb.
                                Máme proto široký výběr motobaterií a autobaterií různého typu a určení.
                                Moderní velkokapacitní skladové prostory jsou zárukou rychlé cesty a dostupnosti až ke
                                koncovému uživateli. Včetně autobaterií VARTA Profesional Deep Cycle AGM.
                                Zajištění logistiky od kusových po kamiónové dodávky. Samozřejmostí je servis a
                                diagnostika stavu baterií.
                                Dále pak marketingový program a reklamní předměty pro podporu prodeje a mnoho dalších
                                výhod soukromé rodinné firmy...
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default WhatWeOffer
