
function WhatWeDo() {
    return (
        <section className="content-homepage homepage-about bg-grey-homepage pt-150">
            <div className="container">
                <div className="row">
                    <h1 className="text-center">Co děláme?</h1>

                    <div className="col-sm-12 col-md-8 ">
                        <div className="homepage-about-quote">
                            <p>
                                Ve světě autobaterií jsme jako doma. Nakupujeme přímo od celosvětové jedničky na
                                tomto trhu firmy Clarios, která každoročně vyrobí přibližně 150.000.000 baterií.
                                Mezi její nejznámější a nejprodávanější značku patří VARTA. Právě s touto značkou jsme
                                spojili před několika lety svojí budoucnost a dnes se již jedná o
                                dlouhodobě fungující obchodní vztah.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default WhatWeDo
