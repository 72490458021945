import logo from './logo.svg';
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Slider from "./components/Slider";
import WhoWeAre from "./components/WhoWeAre";
import WhatWeDo from "./components/WhatWeDo";
import WhatWeOffer from "./components/WhatWeOffer";
import Contact from "./components/Contact";
import Map from "./components/Map";
import ContactForm from "./components/ContactForm";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

function App() {
  return (
      <GoogleReCaptchaProvider reCaptchaKey="6Lcv0PYZAAAAAKbmwa6uKYChBrqa_LTn2QetPR2a">
          <Header></Header>
          <Slider></Slider>
          <WhoWeAre></WhoWeAre>
          <WhatWeDo></WhatWeDo>
          <WhatWeOffer></WhatWeOffer>
          <Contact></Contact>
          <ContactForm></ContactForm>
          <Map></Map>
          <Footer></Footer>
      </GoogleReCaptchaProvider>
  );
}

export default App;
