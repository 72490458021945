import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import {Autoplay, Navigation, Pagination} from "swiper";

function Slider() {
    return (
        <section className="slider">

            <Swiper
                slidesPerView={1}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
            >

                <SwiperSlide>
                    <div className="cover-image" style={{backgroundImage: "url('/images/nn-slider-building.jpg')" }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-3 col-md-offset-9">
                                <div className="slider-info">
                                    <div className="slider-subtitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="cover-image" style={{backgroundImage: "url('/images/nn-slider-batteries.jpg')" }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-3 col-md-offset-9">
                                <div className="slider-info">
                                    <div className="slider-subtitle"></div>
                                    <div className="slider-title yellow">Vyberte si tu Vaší</div>
                                    <div className="spacer"></div>
                                    {/* <div class="right">*/}
                                    {/* <a href="#contacts" class="button button-white sliding-link">kontaktujte nás</a>*/}
                                    {/* </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="cover-image" style={{backgroundImage: "url('/images/nn-slider-building.jpg')" }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-3 col-md-offset-9">
                                <div className="slider-info">
                                    <div className="slider-subtitle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="cover-image" style={{backgroundImage: "url('/images/nn-slider-warehouse.jpg')" }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-5 col-md-offset-7">
                                <div className="slider-info">
                                    <div className="slider-subtitle"></div>
                                    <div className="slider-title yellow">Velkoobchodní i maloobchodní prodej na každé provozovně</div>
                                    <div>
                                        <a href="#contacts" className="button button-white sliding-link">kontaktujte nás</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="cover-image" style={{backgroundImage: "url('/images/nn-slider-reception.jpg')" }}>
                        <div className="row">
                            <div className="col-xs-12 col-md-5 col-md-offset-7">
                                <div className="slider-info">
                                    <div className="slider-subtitle"></div>
                                    <div className="slider-title yellow">…firma plná energie!</div>
                                    <div className="right">
                                        <a href="#contacts" className="button button-white sliding-link">kontaktujte nás</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </section>
    )
}

export default Slider
