function Contact() {
    return (
        <section className="content content-contact" id="contacts">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 text-center contacts">
                        <h1>Kontakt</h1>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-xs-12">

                        <div className="contact-block contact-block-full">
                            <div className="row">
                                <div className="col-xs-12 col-lg-6">
                                    <h3>centrála společnosti milevsko</h3>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6 col-lg-5">
                                            <p>
                                                Čs. legií 1623<br/>
                                                399 01 Milevsko
                                            </p>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-lg-7 contact-block-small">
                                            <a>info@autobaterietollinger.cz</a>
                                            <span>+420 608 983 384</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-lg-6">
                                    <h3>fakturační údaje</h3>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <p>
                                                AUTOBATERIE Tollinger s.r.o.<br/>
                                                Petrská 1426/1<br/>
                                                110 00 Praha
                                            </p>
                                            <p>
                                                IČ 28503295 / DIČ CZ28503295
                                            </p>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <h4>jednatel společnosti</h4>
                                            <span>Milan Tollinger</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12">
                                    <section className="row">

                                        <div className="col-xs-12 col-sm-6 text-center">
                                            <div className="person-teaser">
                                                <img src="/images/empty-avatar.png"/>
                                            </div>
                                            <h4>prodej, reklamace</h4>
                                            <span>Michal Tollinger</span>
                                            <span>michal@autobaterietollinger.cz</span>
                                            <span>+420 725 569 329</span>
                                        </div>

                                        <div className="col-xs-12 col-sm-6 text-center">
                                            <div className="person-teaser">
                                                <img src="/images/empty-avatar.png"/>
                                            </div>
                                            <h4>fakturace a objednávky</h4>
                                            <span>Tereza Bláhová</span>
                                            <span>tereza@autobaterietollinger.cz</span>
                                            <span>+420 382 584 547</span>
                                            <span>+420 608 983 384</span>
                                        </div>

                                    </section>
                                    <section className="row">

                                        <div className="col-xs-12 col-sm-4 text-center">
                                            <div className="person-teaser">
                                                <img src="/images/empty-avatar.png"/>
                                            </div>
                                            <h4>finanční oddělení</h4>
                                            <span>Pavlína Pipková</span>
                                            <span>pavlina@autobaterietollinger.cz</span>
                                        </div>

                                        <div className="col-xs-12 col-sm-4 text-center">
                                            <div className="person-teaser">
                                                <img src="/images/empty-avatar.png"/>
                                            </div>
                                            <h4>finanční oddělení</h4>
                                            <span>Adéla Pejšová</span>
                                            <span>adela@autobaterietollinger.cz</span>
                                            <span>+420 382 584 547</span>
                                        </div>

                                        <div className="col-xs-12 col-sm-4 text-center">
                                            <div className="person-teaser">
                                                <img src="/images/empty-avatar.png"/>
                                            </div>
                                            <h4>obchodní zástupce</h4>
                                            <span>Nikola Kudláčová</span>
                                            <span>nikola@autobaterietollinger.cz</span>
                                            <span>+420 724 963 227</span>
                                        </div>

                                    </section>

                                </div>
                            </div>
                        </div>

                        <div className="contact-block contact-block-left">
                            <h3>Praha</h3>
                            <div className="row">
                                <div className="col-xs-12 col-lg-5">
                                    <p>
                                        Podbabská 1112/13<br/>
                                        160 00 Praha
                                    </p>
                                </div>
                                <div className="col-xs-12 col-lg-7">
                                    <a>praha@autobaterietollinger.cz</a>
                                    {/*<span class="lg">+420 224 315 579</span>*/}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 text-center">
                                    <div className="person-teaser">
                                        <img src="/images/empty-avatar.png"/>
                                    </div>
                                    <h4>Vedoucí prodeje Praha</h4>
                                    <span>Jakub Mareček</span>
                                    <span>jakub@autobaterietollinger.cz</span>
                                    <span>+420 725 066 992</span>
                                </div>
                            </div>
                        </div>

                        <div className="contact-block contact-block-right">

                            <h3>kroměříž</h3>
                            <div className="row">
                                <div className="col-xs-12 col-sm-5">
                                    <p>
                                        Bílanská 4085/75<br/>
                                        767 01 Kroměříž
                                    </p>
                                </div>
                                <div className="col-xs-12 col-sm-7">
                                    <a>info2@autobaterietollinger.cz</a>
                                    <span className="lg">+420 725 003 622</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-4 text-center">
                                    <div className="person-teaser">
                                        <img src="/images/empty-avatar.png"/>
                                    </div>
                                    <h4>Fakturace<br/>a objednávky</h4>
                                    <span>Dana Glozygová</span>
                                    <span>+420 725 499 426</span>
                                </div>
                                <div className="col-xs-12 col-sm-4 text-center">
                                    <div className="person-teaser">
                                        <img src="/images/empty-avatar.png"/>
                                    </div>
                                    <h4>péče o zákazníky</h4>
                                    <span>Stanislav  Wichtora</span>
                                    <span>+420 725 003 622</span>
                                </div>
                                <div className="col-xs-12 col-sm-4 text-center">
                                    <div className="person-teaser">
                                        <img src="/images/empty-avatar.png"/>
                                    </div>
                                    <h4>péče o zákazníky</h4>
                                    <span>Miroslav Wichtora</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
}

export default Contact
