import {useForm} from "react-hook-form";
import {useCallback, useEffect, useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";

function ContactForm() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('SendContactForm');
        console.log(token)
    }, [executeRecaptcha]);

    const [ successSent, setSuccessSent ] = useState(false)
    const [ recaptchaError, setRecaptchaError ] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async data => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('SendContactForm');
        console.log(token)

        const request = {...data, recaptchaToken: token}
        console.log(request)

        try {
            const response = await axios.post(
                'https://me6rrzqinyey6bku6tusskvnje0gjqpc.lambda-url.eu-central-1.on.aws/submit-form', request,
                {
                    headers: {
                        'Method': 'POST',
                        'Content-Type': 'application/json'
                    }
                }
            )
            console.log(response.data)

            setSuccessSent(true)
        } catch (e) {
            const errorResponse = e.response.data
            console.log(errorResponse)

            if (errorResponse.code == 'recaptchaFailed') {
                setRecaptchaError(true)
            }
        }


    };

    console.log(errors)

    return (
        <section className="content content-homepage" id="contact-form">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <h1>Napište nám</h1>
                    </div>

                    {successSent && (
                        <div className="col-xs-12 alert alert-success">
                            <p>Děkujeme za Váš vzkaz, brzy Vás budeme kontaktovat</p>
                        </div>
                    )}

                    {recaptchaError && (
                        <div className="col-xs-12 alert alert-warning">
                            <p>Omlouváme se, ale vyskytl se problém při odesílání, zkuste to prosím znovu.</p>
                        </div>
                    )}

                    {!successSent && (
                    <div className="col-xs-12 col-sm-8 col-sm-push-2">

                        <section className="form-wrapper form-wrapper-homepage">
                            <form method="post" id="registration-form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-xs-12 col-sm-12">
                                        <h3>Vaše údaje</h3>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 form-item">
                                        <label htmlFor="id_company_name">Společnost</label>
                                        <input
                                            {...register("company", {
                                                required: false,
                                                maxLength: 30})}
                                            id="id_company_name"
                                        />
                                    </div>

                                    <div className="col-xs-12 col-sm-6 form-item">
                                        <label htmlFor="id_first_name">Jméno *</label>
                                        <input
                                            className={errors?.firstName ? 'error-field' : ''}
                                            {...register("firstName", {
                                                required: true,
                                                maxLength: 30})}
                                            id="id_first_name"
                                        />
                                        {errors?.firstName?.type === "required" && <span className="error-message">Pole je povinné</span>}
                                        {errors?.firstName?.type === "maxLength" && <span className="error-message">Max. délka je 30 znaků</span>}
                                    </div>

                                    <div className="col-xs-12 col-sm-6 form-item">
                                        <label htmlFor="id_last_name">Přijmení *</label>
                                        <input
                                            className={errors?.lastName ? 'error-field' : ''}
                                            {...register("lastName", {
                                                required: true,
                                                maxLength: 30})}
                                            id="id_last_name"
                                        />
                                        {errors?.lastName?.type === "required" && <span className="error-message">Pole je povinné</span>}
                                        {errors?.lastName?.type === "maxLength" && <span className="error-message">Max. délka je 30 znaků</span>}
                                    </div>

                                    <div className="col-xs-12 col-sm-6 form-item">
                                        <label htmlFor="id_email">E-mail *</label>
                                        <input
                                            className={errors?.email ? 'error-field' : ''}
                                            {...register("email", {
                                                required: true,
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Entered value does not match email format"
                                                },
                                                maxLength: 200})}
                                            id="id_email"
                                        />
                                        {errors?.email?.type === "required" && <span className="error-message">Pole je povinné</span>}
                                        {errors?.email?.type === "pattern" && <span className="error-message">Pole musí být validní email</span>}
                                        {errors?.email?.type === "maxLength" && <span className="error-message">Max. délka je 200 znaků</span>}
                                    </div>

                                    <div className="col-xs-12 col-sm-6 form-item">
                                        <label htmlFor="id_phone_number">Telefonní číslo *</label>
                                        <input
                                            className={errors?.phoneNumber ? 'error-field' : ''}
                                            {...register("phoneNumber", {
                                                required: true,
                                                maxLength: 14})}
                                            id="id_phone_number"
                                        />
                                        {errors?.phoneNumber?.type === "required" && <span className="error-message">Pole je povinné</span>}
                                        {errors?.phoneNumber?.type === "maxLength" && <span className="error-message">Max. délka je 14 znaků</span>}
                                    </div>

                                    <div className="col-xs-12 col-sm-12">
                                        <h3>Zpráva pro nás</h3>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 form-item">
                                        <label htmlFor="form_message">Zpráva *</label>
                                        <textarea
                                            className={errors?.message ? 'error-field' : ''}
                                            {...register("message", {
                                                required: true})}
                                            rows="5"
                                            id="form_message">
                                        </textarea>
                                        {errors?.message?.type === "required" && <span className="error-message">Pole je povinné</span>}
                                    </div>

                                    <div className="col-xs-12 col-lg-6 col-lg-offset-3 form-item">
                                        <button type="submit" className="button button-blue">odeslat</button>
                                    </div>
                                </div>
                            </form>
                        </section>

                    </div>
                    )}
                </div>
            </div>
        </section>
)
}

export default ContactForm
