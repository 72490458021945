
function Footer() {
    return (
        <footer>
            <div className="footer-top">
                <div className="container">
                    <div className="row">


                        <div className="hidden-xs col-sm-6 col-md-4 footer-address">
                            <div className="title">Centrála společnosti</div>
                            <p><i className="icon icon-address"></i>
                                Čs. legií 1623<br/> 399 01 Milevsko<br/>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 footer-info">
                            <div className="title">Kontaktní informace</div>
                            <p>
                                <i className="icon icon-email"></i>
                                <a href="mailto: info@autobaterietollinger.cz">info@autobaterietollinger.cz</a>
                            </p>
                            <p className="footer-contact">
                                <i className="icon icon-phone"></i>
                                <span className="footer-phone">+420 382 584 547</span>
                            </p>
                            <p className="footer-contact">
                                <i className="icon icon-mobile"></i>
                                <span className="footer-mobil">+420 608 983 384</span>
                            </p>
                        </div>

                        <div className="hidden-xs col-sm-4 col-md-4 footer-pin">
                            <div className="title">Fakturační údaje</div>
                            <p>
                                <i className="icon icon-pin"></i>
                                AUTOBATERIE Tollinger s.r.o.<br/>
                                Petrská 1426/1<br/>
                                110 00 Praha<br/>
                            </p>
                            <p>
                                <i className="icon icon-identity-card"></i>
                                IČO 28503295 / DIČ CZ28503295
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom text-center">
                © Autobaterie Tollinger 2020
            </div>
        </footer>
    )
}

export default Footer
