
function WhoWeAre() {
    return (
        <section className="content-homepage homepage-about bg-white-homepage pt-150" name="about">
            <div className="container">
                <h1 className="text-center">Kdo jsme?</h1>

                <img src="/images/milan-tollinger-1800.png" alt="Milan Tollinger" className="homepage-about-teaser visible-md visible-lg"/>
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-md-offset-4">
                            <div className="homepage-about-quote white">
                                <p>
                                    Velmi nás těší Váš zájem o naše produkty a služby. Jsme specializovaný
                                    velkoobchod zabývající se distribucí a sběrem autobaterií. Na trhu
                                    působíme
                                    již od roku 1992. Nakupujeme, coby autorizovaný dealer značky Varta,
                                    přímo
                                    z výrobního závodu Clarios Česká Lípa.
                                </p>
                                <p>
                                    Firma Clarios je největším světovým výrobcem autobaterií
                                    a zároveň nejsilnějším dodavatelem do automobilového průmyslu. Všichni
                                    naši zaměstnanci jsou připravení věnovat se Vám, našim zákazníkům.
                                </p>
                            </div>
                            <p>
                                Děkujeme za důvěru.<br/>
                                <strong>Milan Tollinger</strong>
                            </p>
                            <div>
                                <a href="#kontakty" className="button button-white button-yellow">Kontakty</a>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
)
}

export default WhoWeAre
