import {GoogleMap, InfoWindow, LoadScript, Marker} from "@react-google-maps/api";

const containerStyle = {
    width: '100%',
    height: '500px'
};

const center = {
    lat: 50.0877679,
    lng: 14.45282270000007
};


function Map() {
    return (
        <>

            <section id="map">
                <LoadScript googleMapsApiKey="AIzaSyBw7qr2eBXgWhJXcm-OdSotPr_4wQRAmkg">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={7}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        <Marker
                            label="Some label."
                            title="Praha"
                            position={{lat: 50.114043, lng: 14.393474}}
                        >
                            {/*<InfoWindow>*/}
                            {/*    /!*<div className="map-title">Praha</div>*!/*/}
                            {/*    <p className="map-content">*/}
                            {/*        Podbabská 1112/13, Dejvice, Praha<br/>*/}
                            {/*        <a href="mailto:info@autobaterie-tollinger.cz">info@autobaterie-tollinger.cz</a><br/>*/}
                            {/*        +420 555 444 333*/}
                            {/*    </p>*/}
                            {/*</InfoWindow>*/}

                        </Marker>
                    </GoogleMap>
                </LoadScript>
            </section>

            <section id="map-list">
                <div className="map-item" data-lat="50.114043" data-lng="14.393474">
                    <div className="map-title">Praha</div>
                    <p className="map-content">
                        Podbabská 1112/13, Dejvice, Praha<br/>
                        <a href="mailto:info@autobaterie-tollinger.cz">info@autobaterie-tollinger.cz</a><br/>
                        +420 555 444 333
                    </p>
                </div>
                <div className="map-item" data-lat="49.441276" data-lng="14.359475">
                    <div className="map-title">Milevsko</div>
                    <p className="map-content">
                        Čs.legií 1623, 399 01 Milevsko<br/>
                        <a href="mailto:milevsko@autobaterie-tollinger.cz">milevsko@autobaterie-tollinger.cz</a><br/>
                        +420 382 584 547
                    </p>
                </div>
                <div className="map-item" data-lat="49.307997" data-lng="17.410424">
                    <div className="map-title">Kroměříž</div>
                    <p className="map-content">
                        Jožky Silného 2349/4, Kroměříž<br/>
                        <a href="mailto:kromeriz@autobaterie-tollinger.cz">kromeriz@autobaterie-tollinger.cz</a><br/>
                        +420 555 444 222
                    </p>
                </div>
            </section>
        </>
    )
}

export default Map
