import {useRef} from "react";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


// const scroll = (selector) => {
//     console.log('scrolling to ' + selector)
//     const section = document.querySelector( selector );
//     section.scrollIntoView( { behavior: 'smooth' } );
// };

const useScroll = () => {
    const elRef = useRef(null);
    const executeScroll = () => elRef.current.scrollIntoView();
    return [executeScroll, elRef];
};

function Header() {
    return (
        <header>
            <div className="container">
                <div className="row">

                    <div className="col-xs-4 visible-md visible-lg text-right">
                        <ul id="menu-left" className="menu-header">
                            <li className="">
                                <Link to="about" smooth={true} duration={500} href={""}>
                                    <i className="icon icon-identity-card"></i>
                                    <span>o nás</span>
                                </Link>

                            </li>
                            <li className="">
                                <Link to="contacts" smooth={true} duration={500} href={""}>
                                    <i className="icon icon-phone"></i>
                                    <span>kontakty</span>
                                </Link>
                            </li>
                        </ul>
                    </div>


                    <div className="col-xs-12 col-md-4 text-center header-logo">
                        <a href="/" id="logo"><img src="/images/logo.png"/></a>
                    </div>

                    <div className="col-xs-4 visible-md visible-lg">
                        <ul id="menu-right" className="menu-header">
                            <li>
                                <Link to="contact-form" smooth={true} duration={500} href={""}>
                                    <i className="icon icon-clipboard"></i>
                                    <span>kontaktní formulář</span>
                                </Link>
                            </li>
                            <li className="">
                                <a href="https://obchod.autobaterietollinger.cz/" target="_blank">
                                    <i className="icon icon-pricelist"></i>
                                    <span>zákaznická zóna</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default Header;
